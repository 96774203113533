$toolbar-breakpoint: 600px;

$fa-font-path: '../node_modules/@fortawesome/fontawesome-free-webfonts/webfonts';

$link-hover-decoration: none;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);
