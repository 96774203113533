@import '~@angular/material/theming';

@mixin lamentaliste-child-component-theme($theme) {
  $warn: map-get($theme, warn);

  lamentaliste-child {
    > div {
      border-color: mat-color($warn);

      > h2 {
        color: mat-color($warn);
      }
    }
  }
}
