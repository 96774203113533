@import '~@angular/material/theming';

@mixin lamentaliste-app-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  //$back: map-get($theme, back);

/*  .backgroundPanel{
//    background-color:whitesmoke;
    //background-color: mat-color($back);
    padding: 15px;
  }*/
  
  .background {
    padding: 20px 0 0 0;
    position: relative;
    background: url('/assets/intro8.jpg') no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    
    //opacity: 0.7;
    content: '';
    z-index: 0;
    height:100%;
  
    .actions {
      text-align: center;
      margin: 50px;
      z-index: 1;
  
      span {
        display: inline-block;
        font-weight: bold;
        padding: 20px 10px 30px 10px;
      }
  
      a {
        margin: 0 5px 10px 0;
  
        &.actions-main {
          text-transform: uppercase;
          padding: 3px 24px;
        }
  
        fa-icon {
          position: relative;
          bottom: 1px;
        }
      }
    }
  
    .get-started {
      max-width: 700px;
      margin: 50px auto;
      letter-spacing: 0.01px;
      overflow-wrap: break-word;
      z-index: 1;
      overflow: hidden;
  
      code {
        font-size: 0.9em;
        display: inline-block;
        word-wrap: break-word;
        white-space: normal;
        margin: 0 0 10px 0;
      }
    }
  }

  mat-sidenav-container {
    mat-toolbar {
      button {
        &.active {
          color: mat-color($accent, default-contrast);
          background-color: mat-color($accent);
        }
      }

      .link {
        color: mat-color($primary, default-contrast);
      }
    }

    mat-sidenav {
      .mat-list-item {
        &.active {
          color: mat-color($accent, default-contrast);
          background-color: mat-color($accent) !important;

          &:hover {
            background-color: mat-color($accent, darker) !important;
          }
        }
      }
    }

    .footer {
      color: mat-color($primary, lighter);
      background-color: mat-color($primary, darker);

      .links,
      .signature {
        a {
          color: mat-color($primary, lighter);
          &:hover {
            color: mat-color($accent);
          }
        }
      }
    }
  }
}
