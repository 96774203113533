@import '~@angular/material/theming';

@mixin lamentaliste-stock-market-container-component-theme($theme) {
  $warn: map-get($theme, warn);

  lamentaliste-stock-market {
    mat-card {
      .negative {
        color: mat-color($warn);
      }
    }

    .error {
      fa-icon {
        color: mat-color($warn);
      }
    }
  }
}
